<template>
	<Metier name="achats" title="Achats" :main-menu="[{title: 'Contremaître principal achats', missions: ['Coordonne les achats de matériel et de prestations', 'Assiste les différents services dans la définition de leurs besoins', 'Négocie les contrats avec les prestataires et les entreprises extérieures', 'S\'assure du respect des procédures, consignes de sécurité et des règles liées au processus d\'achat au sein du service'], competences: ['Connaissance des équipements et procédés de la plateforme', 'Connaissance des processus et techniques d\'achat', 'Connaissance des règles et principes de gestion comptable', 'Connaissance des marchés'], qualites: ['Leadership', 'Sens du contact, de la négociation', 'Organisation', 'Impartialité']}, {title: 'Acheteur', missions: ['Participe à l\'élaboration du Plan Achats annuel de la plateforme et la met en œuvre', 'S\'assure de la bonne application des contrats actifs et évalue les performances des fournisseurs', 'Assure la gestion administrative des contrats dans le logiciel interne de comptabilité'], competences: ['Formation en maintenance ou matériel industriel', 'Connaissance de la plateforme et de son organisation', 'Connaissance des processus et techniques d\'achat', 'Connaissance des marchés'], qualites: ['Sens du contact, de la négociation', 'Rigueur', 'Organisation']}, {title: 'Technicien Magasin', missions: ['Lance les approvisionnements des pièces et produits et s\'assure du respect des délais de livraison', 'Assure la gestion administrative des stocks de pièces et produits', 'Cherche à optimiser les niveaux de stocks via l\'analyse des surstocks ou stocks morts', 'Veille au bon état des magasins et au respect des procédures'], competences: ['Formation en gestion logistique ou expérience équivalente', 'Connaissance des procédures administratives liées à la gestion de matériel', 'Maîtrise des outils informatiques'], qualites: ['Rigueur', 'Sens du contact', 'Organisation']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
